<template>
  <div>

    <div class="row">
      <div class="col-md-12">
        <Card :title="'Edit Bangsal'">
          <template v-slot:body>
            <div>

              <Form :form="form" :route="'beds/'+ form.id" />

            </div>
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from '@/component/beds/Form.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import module from '@/core/modules/CrudModule.js'

export default {

  data() {
    return {
      // Form
      form: {
        bed_category_id:"",
        name:"",
        description:"",
      }
    }
  },

  components: {
    Card,
    Form
  },

  methods:{
    async get() {
      this.form = await module.get('beds/' + this.$route.params.id)
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push('/masters/beds/list')
      } else {
        this.form['_method'] = 'put'
        this.dataLoaded = true
      }
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Bangsal", route: "" },
      { title: "Daftar Bangsal", route: "/beds/list" },
      { title: "Edit Bangsal" },
    ])
    this.get()
  },

}
</script>

<style>
</style>